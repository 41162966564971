<template>
  <div class="container">
    <CustomCard>
      <SubHead>订单信息</SubHead>
      <el-divider></el-divider>
      <el-form label-position="left" label-width="110px">
        <el-row :gutter="30">
          <GridItem>
            <el-form-item label="售后单号">{{
              detailInfo.base_info.refund_service_id
            }}</el-form-item>
          </GridItem>
          <GridItem>
            <el-form-item label="售后类型">{{
              detailInfo.base_info.refund_type_name || '仅退款'
            }}</el-form-item>
          </GridItem>
          <GridItem>
            <el-form-item label="发货状态">{{
              detailInfo.base_info.send_status_name || '未发货'
            }}</el-form-item>
          </GridItem>
          <GridItem v-if="detailInfo.base_info.refund_flag != 2">
            <el-form-item label="退款金额">
              <text v-if="detailInfo.base_info.refund_flag == 3"> - </text>
              <text v-else>
                +¥ {{ detailInfo.base_info.refund_amount / 100 }}</text
              >
            </el-form-item>
          </GridItem>
          <GridItem
            v-if="
              detailInfo.base_info.refund_type == 2 &&
              detailInfo.base_info.refund_flag == 2
            "
          >
            <el-form-item label="再支付金额"
              >+{{ detailInfo.base_info.refund_amount / 100 }}</el-form-item
            >
          </GridItem>
          <GridItem>
            <el-form-item label="售后状态">{{
              detailInfo.base_info.refund_name
            }}</el-form-item>
          </GridItem>
          <GridItem v-if="detailInfo.base_info.fail_reason">
            <el-form-item label="售后原因">{{
              detailInfo.base_info.fail_reason
            }}</el-form-item>
          </GridItem>
          <GridItem>
            <el-form-item label="售后申请时间">{{
              detailInfo.base_info.order_create_time
            }}</el-form-item>
          </GridItem>
          <GridItem>
            <el-form-item label="下单时间">{{
              detailInfo.base_info.create_time
            }}</el-form-item>
          </GridItem>
          <GridItem>
            <el-form-item label="订单编号">{{
              detailInfo.base_info.order_id
            }}</el-form-item>
          </GridItem>
          <!-- <GridItem>
                        <el-form-item label="配送单号">{{ detailInfo.base_info.refund_service_id }}</el-form-item>
                    </GridItem> -->
          <GridItem>
            <el-form-item label="申请说明">{{
              detailInfo.base_info.remark
            }}</el-form-item>
          </GridItem>
        </el-row>
      </el-form>
      <SubHead>{{
        detailInfo.replace_product_list.length > 0 ? '原商品信息' : '商品信息'
      }}</SubHead>
      <el-divider></el-divider>
      <el-table
        :data="detailInfo.base_pop_order_product_vo"
        :header-cell-style="{
          'text-align': 'center',
          background: '#fafafa',
          color: '#444'
        }"
        :cell-style="{ 'text-align': 'center' }"
        border
      >
        <el-table-column label="商品信息" prop="" width="250">
          <template v-slot="{ row }">
            <div class="box">
              <el-image
                style="width: 100px; height: 100px"
                :src="row.image_url"
              ></el-image>
              <div class="info" style="margin-left: 5px">
                <div style="text-align: left">{{ row.commodity_name }}</div>
                <div style="text-align: left">SKU编码：{{ row.sku }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="单价(元)" prop="">
          <template v-slot="{ row }">
            {{ row.unit_price / 100 }}
          </template>
        </el-table-column>
        <el-table-column label="数量" prop="sale_number"></el-table-column>
        <el-table-column label="实付金额" prop="">
          <template v-slot="{ row }">
            {{ row.sale_amount / 100 }}
          </template>
        </el-table-column>
        <el-table-column
          label="售后数量"
          prop="apply_refund_num"
        ></el-table-column>
        <!-- <el-table-column label="退款金额" prop="">
          <template v-slot="{ row }">
            {{ row.refund_amount / 100 }}
          </template>
        </el-table-column> -->
        <el-table-column
          label="支付流水号"
          prop="pay_serial_number"
        ></el-table-column>
      </el-table>
      <template v-if="detailInfo.replace_product_list.length > 0">
        <SubHead>换货商品信息</SubHead>
        <el-divider></el-divider>
        <el-table
          :data="detailInfo.replace_product_list"
          :header-cell-style="{
            'text-align': 'center',
            background: '#fafafa',
            color: '#444'
          }"
          :cell-style="{ 'text-align': 'center' }"
          border
          ref="multipleTable"
          :row-key="(row) => row.order_id"
        >
          <el-table-column label="商品信息" prop="" width="350">
            <template v-slot="{ row }">
              <div class="box">
                <el-image style="width: 100px" :src="row.image_url"></el-image>
                <div class="info">
                  <div style="text-align: left">
                    {{ row.commodity_name }}
                  </div>
                  <div style="text-align: left">SKU编码：{{ row.sku }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="外部商品信息" prop="" width="200">
            <template v-slot="{ row }">
              {{ row.middle_sku }} {{ row.middle_sku_name }}
            </template>
          </el-table-column>
          <el-table-column label="是否为赠品" prop="">
            <template v-slot="{ row }">
              {{ row.commodity_type ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column label="单价（元）" prop="">
            <template v-slot="{ row }">
              <div class="i-nowrap">
                {{ row.unit_price / 100 }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="商品数量" prop="">
            <template v-slot="{ row }">
              <div>
                {{ row.sale_number - row.already_refund_number }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="商品金额">
            <template v-slot="{ row }">
              <div class="i-nowrap">
                {{ row.total_original_price / 100 }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="大客户优惠">
            <template v-slot="{ row }">
              <div class="i-nowrap">
                {{ row.customer_amount / 100 }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="运费">
            <template v-slot="{ row }">
              <div class="i-nowrap">
                {{ row.post_amount / 100 }}
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="实付金额">
            <template v-slot="{ row }">
              <div style="color: #9e2714" class="i-nowrap">
                {{ row.sale_amount / 100 }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <SubHead>收货信息</SubHead>
      <el-divider></el-divider>
      <div style="margin: 20px 0; text-align: center; color: gray; width: 100%">
        收件人信息不支持查看
      </div>
      <SubHead>物流信息</SubHead>
      <el-divider></el-divider>
      <LogisticesInfo
        :showLogisticsList="detailInfo && detailInfo.logistics_list"
      />
      <SubHead>退款日志</SubHead>
      <el-divider></el-divider>
      <el-table
        :data="detailInfo.log_list"
        :header-cell-style="{
          'text-align': 'center',
          background: '#fafafa',
          color: '#444'
        }"
        :cell-style="{ 'text-align': 'center' }"
        border
      >
        <el-table-column label="操作时间" prop="create_time"></el-table-column>
        <el-table-column
          label="操作人"
          prop="create_converge_name"
        ></el-table-column>
        <el-table-column
          label="跟踪信息"
          prop="node_description"
        ></el-table-column>
      </el-table>
      <template #bottomOperation>
        <div v-if="detailInfo.base_info.has_approve_button == 1">
          <el-button type="primary" @click="refundOrder(2)">同意退款</el-button>
          <el-button @click="refundOrder(1)">驳回退款</el-button>
        </div></template
      >
    </CustomCard>
    <el-dialog title="驳回原因" v-model="rejectPopup" width="40%">
      <div class="i-p-10">
        <el-input
          class="i-width-full"
          type="textarea"
          v-model="failReason"
          placeholder="请输入驳回原因"
        ></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="handleRejectSubmit">提交</el-button>
          <el-button @click="rejectPopup = false">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import LogisticesInfo from '@/components/LogisticsInfo'
import { useRoute } from 'vue-router'
import { getPopRefundOrderDetail, approveRefundService } from '@/api/aftersale'
import { useDoubleConfirm } from '@/hooks/useDoubleConfirm'
import { ElMessage } from 'element-plus'

const route = useRoute()
const detailInfo = reactive({
  base_info: {},
  base_pop_order_product_vo: [],
  address_info: {},
  logistics_list: [],
  log_list: [],
  replace_product_list: []
})
const rejectPopup = ref(false)
const failReason = ref('')
onMounted(() => {
  _getPopRefundOrderDetail()
})
const _getPopRefundOrderDetail = () => {
  getPopRefundOrderDetail({
    refund_service_id: route.params.id
  }).then((res) => {
    const {
      base_info,
      base_pop_order_product_vo,
      address_info,
      logistics_list,
      log_list,
      replace_product_list
    } = res
    detailInfo.base_info = base_info
    detailInfo.base_pop_order_product_vo = [base_pop_order_product_vo]
    detailInfo.address_info = address_info
    detailInfo.logistics_list = logistics_list || []
    detailInfo.log_list = log_list || []
    detailInfo.replace_product_list = replace_product_list || []
  })
}
const refundOrder = async (approve_status) => {
  if (!(await useDoubleConfirm('确认进行此次操作嘛？', '提示', ''))) return
  if (approve_status === 2) {
    submitApproveRefundService(approve_status)
  } else {
    rejectPopup.value = true
  }
}
const submitApproveRefundService = (approve_status) => {
  approveRefundService({
    order_id: detailInfo.base_info.order_id,
    refund_service_id: detailInfo.base_info.refund_service_id,
    approve_status,
    fail_reason: failReason.value
  }).then(() => {
    rejectPopup.value = false
    ElMessage({
      type: 'success',
      message: '操作成功！'
    })
    _getPopRefundOrderDetail()
  })
}
const handleRejectSubmit = () => {
  if (!failReason.value) {
    ElMessage('请输入驳回原因')
    return
  }
  submitApproveRefundService(1)
}
</script>

<style lang="scss">
.container {
  .box {
    display: flex;
    justify-content: space-around;

    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
}
</style>
