<template>
  <div style="max-height: 750px; overflow: auto">
    <el-table
      :data="processedShowLogisticsList"
      border
      :cell-style="cellStyle"
      :header-cell-style="headerCellStyle"
    >
      <el-table-column v-if="ownSell" width="200" label="发货仓" v-slot="scope">
        {{ scope.row.warehouse_id }} {{ scope.row.warehouse_name }}
      </el-table-column>
      <el-table-column
        width="200"
        label="物流公司名称"
        prop="company_name"
      ></el-table-column>
      <el-table-column width="200" label="物流单号" v-slot="scope">
        <div style="cursor: pointer" @click="copy(scope.row.waybill_number)">
          <span>{{ scope.row.waybill_number }}</span>
          <i class="el-icon-document-copy"></i>
        </div>
      </el-table-column>
      <el-table-column label="物流跟踪动态" v-slot="scope">
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in scope.row.trajectory_list.slice(
              0,
              showListLengthArr[scope.$index].showCount
            )"
            :key="index"
            :icon="activity.icon"
            :type="activity.type"
            :color="index === 0 && color"
            :size="activity.size"
            :timestamp="activity.node_time"
          >
            <span :style="`${index === 0 && `color:${color}`}`">{{
              activity.node_remark
            }}</span>
          </el-timeline-item>
        </el-timeline>
        <div
          class="more-info"
          v-if="scope.row.trajectory_list.length > 3"
          @click="
            handleClick(
              scope.$index,
              scope.row.trajectory_list.length,
              !showListLengthArr[scope.$index].showAll
            )
          "
        >
          <i
            :class="`i-text-primary el-icon-arrow-${
              showListLengthArr[scope.$index].showAll ? 'up' : 'down'
            } icon`"
          />
          <el-link type="primary">{{
            showListLengthArr[scope.$index].showAll
              ? '收起'
              : '展开更多物流信息'
          }}</el-link>
        </div>
      </el-table-column>
      <el-table-column
        v-if="ownSell"
        width="200"
        label="商品信息"
        v-slot="scope"
      >
        <template v-if="scope.row.package_vo_list">
          <div v-for="(item, index) in scope.row.package_vo_list" :key="index">
            {{ item.sku }} * {{ item.sku_number }}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, computed, defineProps } from 'vue'
import { ElMessage } from 'element-plus'

const color = ref('#20B120')
const showListLength = ref(3)
const showListLengthArr = ref([])

const props = defineProps({
  showLogisticsList: Array,
  ownSell: {
    type: Boolean,
    default: false
  }
})

const processedShowLogisticsList = computed(() => {
  return props.showLogisticsList.map((item) => ({
    ...item,
    trajectory_list: item.trajectory_list || []
  }))
})

const cellStyle = { 'text-align': 'center' }

const headerCellStyle = {
  'text-align': 'center',
  background: '#fafafa',
  color: '#444'
}

onBeforeMount(() => {
  // showListLengthArr.value = Array.from(new Array(props.showLogisticsList.length), () => showListLength.value)
  showListLengthArr.value = new Array(props.showLogisticsList.length).fill({
    showCount: showListLength.value,
    showAll: false
  })
})

const handleClick = (idx, length, showAll) => {
  showListLengthArr.value = showListLengthArr.value.map((item, index) => {
    return {
      showCount:
        idx === index
          ? showAll
            ? length
            : showListLength.value
          : item.showCount,
      showAll: idx === index ? showAll : item.showAll
    }
  })
}

const copy = (data) => {
  const url = data
  const oInput = document.createElement('input')
  oInput.value = url
  document.body.appendChild(oInput)
  oInput.select() // 选择对象;
  document.execCommand('Copy') // 执行浏览器复制命令
  ElMessage({
    message: '复制成功',
    type: 'success'
  })
  oInput.remove()
}
</script>

<style lang="scss" scoped>
.more-info {
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon {
    cursor: pointer;
    color: #a5afb8;
    margin-left: 40px;
    margin-right: 14px;
  }
}

:deep .el-timeline-item__wrapper {
  text-align: left !important;
}
</style>
